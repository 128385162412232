<template>
    <login-page></login-page>
</template>

<script>
import LoginPage from '../../components/login/LoginPage';

export default {
    name: 'Login',
    components: { LoginPage }
};
</script>

<style scoped>

</style>
