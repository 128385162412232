<template>
    <div class="login-page bg-6 of-h">

        <div class="row p-0 m-0" style="min-height: 100vh;">
            <div class="col-12 col-lg-6 sec">
                <div @click="$router.push('/')" class="pl-lg-5 ml-lg-5 mt-5 cursor-pointer home-icon">
                </div>
                <div class="fl-y fl-j-c h-88">
                    <div class="sec-1 ">
                        <p class="grey"></p>

                        <div class="lg-no-dis set-im mb-5 mb-lg-0">
                            <img class="" src="../../assets/web/login/logo_login.png">
                        </div>
                        <p v-if="section==='login'" class="font-jost-semi-bold fs-6 mt-5  mb-1">Login Portal</p>
                        <p v-else class="font-jost-semi-bold fs-6 mt-5  mb-1">Forgot Password</p>
                        <p v-if="section==='login'" class="font-lato-bold fs-1">
                            Please enter your details below to login
                        </p>
                    </div>
                    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                        <s-form ref="loginForm" role="form" @submit="handleSubmit(submitClicked)">
                            <div class="section2 mt-4 row nobs-no">
                                <template v-if="section==='login'">
                                    <div class="col-12">
                                        <div class="fl-x fl-j-c">
                                            <validated-input label="Email/Phone Number" size="sm" border-radius="1"
                                                             name="Email/Phone Number"
                                                             class="py-1 form-input-w-22re c-input-select-log-white c-login-input font-proxima-semi-bold"
                                                             v-model="model.username"
                                                             :disabled="loading" :rules="rules.username">
                                            </validated-input>
                                        </div>

                                    </div>
                                    <div class="col-12">
                                        <div class="fl-x fl-j-c">
                                            <validated-input label="Password" size="sm" border-radius="1"
                                                             name="Password"
                                                             type="password"
                                                             class="py-1 form-input-w-22re  mt-2 font-proxima-semi-bold c-input-select-log-white  c-login-input"
                                                             v-model="model.password"
                                                             :disabled="loading" :rules="rules.password">
                                            </validated-input>
                                        </div>
                                        <div class="fl-x fl-j-c">
                                            <div class="py-1 w-22re c-input-select-log-white text-danger"
                                                 v-if="errorMessage"
                                                 v-html="errorMessage"></div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="fl-j-c fl-x">
                                            <div class="fl-x fl-j-e w-22re">
                                                <p @click="setSection('forgot-password')"
                                                   class="font-proxima-semi-bold fs--1 text-primary mt-1 mb-4 cursor-pointer">
                                                    Forgot password?
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col-12">
                                        <div class="fl-x fl-j-c">
                                            <validated-input label="E-Mail" size="sm" border-radius="1" name="Email"
                                                             class="py-1 form-input-w-22re c-input-select-log-white c-login-input font-proxima-semi-bold"
                                                             v-model="forgotMail" :disabled="forgotPasswordLoading"
                                                             :rules="{required:true}">
                                            </validated-input>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="fl-x fl-j-c">
                                            <div
                                                class="py-1 w-22re c-input-select-log-white font-proxima-semi-bold text-muted"
                                                v-if="forgotPasswordMessage"
                                                v-html="forgotPasswordMessage"></div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="fl-j-c fl-x">
                                            <div class="fl-x fl-j-e w-22re">
                                                <p @click="setSection('login')"
                                                   class="font-proxima-semi-bold fs--1 text-primary mt-1 mb-4 cursor-pointer">
                                                    Back to Login
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <div class="section-3 col-12  mt-3">
                                    <btn v-if="section==='login'" @click="clearForm" type="button" border-radius="1"
                                         text="Clear" class="pbtn-1 text-black  mr-4 font-proxima-semi-bold"
                                         design="basic-b"></btn>
                                    <btn v-if="section==='login'" type="submit" border-radius="1"
                                         class="pbtn-1 font-proxima-semi-bold "
                                         text="Login" loading-text="Validating..." :disabled="loading"
                                         :loading="loading"></btn>
                                    <btn v-if="section!=='login'" border-radius="1"
                                         class="pbtn-1 font-proxima-semi-bold "
                                         text="Submit" type="button" @click="forgotPassword"
                                         loading-text="Submitting..." :disabled="forgotPasswordLoading"
                                         :loading="forgotPasswordLoading"></btn>
                                    <p @click="$router.push('/register/')"
                                       class=" font-proxima-semi-bold fs--1 mt-4 cursor-pointer">Are you
                                        first time user?<span
                                            class="text-primary font-proxima-semi-bold"> Register Now </span>
                                    </p>
                                    <!--                                    <div style=" min-height:30vh;" class="social-bg">-->
                                    <!--                                        <h6 class="font-proxima-semi-bold  mb-3">Sign up with</h6>-->
                                    <!--                                        <div class="sm-res">-->
                                    <!--                                            <img class="gugl fl-j-c mr-2" src="../../assets/web/login/g.png">-->

                                    <!--                                            <img class="fb flj-c " src="../../assets/web/login/fb.png">-->

                                    <!--                                        </div>-->
                                    <!--                                    </div>-->

                                </div>
                                <div class="strip-color"></div>
                            </div>
                        </s-form>
                        <div class="section2 mt-5 nobs ">
                            <s-form ref="loginForm" role="form" @submit="handleSubmit(submitClicked)">
                                <div class="mt-4"></div>
                                <div v-if="section==='login'">
                                    <label class="sec-left font-proxima-semi-bold  fs--2">Email/Phone Number</label>
                                    <validated-input size="sm" border-radius="0" name="Username" id="username"
                                                     class="py-2 px-5 c-input-select-log-white font-proxima-semi-bold"
                                                     v-model="model.username"
                                                     :disabled="loading" :rules="{required:true}">
                                    </validated-input>

                                    <label class="sec-left font-proxima-semi-bold fs--2">Password</label>
                                    <validated-input size="sm" border-radius="0" name="Password" id="password"
                                                     type="password"
                                                     class="py-2 px-5 b-c c-input-select-log-white font-proxima-semi-bold"
                                                     v-model="model.password"
                                                     :disabled="loading" :rules="rules.password">
                                    </validated-input>
                                    <div class="py-1 px-8 c-input-select-log-white text-danger " v-if="errorMessage"
                                         v-html="errorMessage"></div>
                                    <p @click="setSection('forgot-password')"
                                       class="font-proxima-semi-bold fs--2 text-primary text-right px-5 mb-4 cursor-pointer">
                                        Forgot password?
                                    </p>
                                </div>
                                <div v-else>
                                    <label class="sec-left font-proxima-semi-bold  fs--2">E-Mail</label>
                                    <validated-input size="sm" border-radius="0" name="email" id="email"
                                                     class="py-2 px-5 c-input-select-log-white font-proxima-semi-bold"
                                                     v-model="forgotMail" :disabled="forgotPasswordLoading"
                                                     :rules="{required:true}">
                                    </validated-input>
                                    <p class="py-2 px-5 c-input-select-log-white font-proxima-semi-bold text-muted"
                                       v-if="forgotPasswordMessage"
                                       v-html="forgotPasswordMessage"></p>
                                    <p @click="setSection('login')"
                                       class="font-proxima-semi-bold fs--2 text-primary text-right px-5 mb-4 cursor-pointer">
                                        Back to Login
                                    </p>
                                </div>
                                <div class="section-3  mt-3 ">
                                    <btn v-if="section==='login'" @click="model={}" border-radius="1" text="Clear"
                                         class="pbtn-1 font-proxima-semi-bold  mr-4"
                                         design="basic-b"></btn>
                                    <btn v-if="section==='login'" type="submit" border-radius="1"
                                         class="pbtn-1 font-proxima-semi-bold"
                                         text="Login"
                                         :disabled="loading" :loading="loading"></btn>
                                    <btn v-if="section!=='login'" border-radius="1"
                                         class="pbtn-1 font-proxima-semi-bold "
                                         text="Submit" type="button" @click="forgotPassword"
                                         loading-text="Submitting..." :disabled="forgotPasswordLoading"
                                         :loading="forgotPasswordLoading"></btn>
                                    <p @click="$router.push('/register/')"
                                       class=" font-proxima-semi-bold fs--2 mt-2 cursor-pointer">Are you
                                        first time user? <span
                                            class="font-proxima-semi-bold text-primary fs--2"> Register Now </span>
                                    </p>
                                    <!--                                    <h6 class="font-inter-Medium mb-2">Sign up with</h6>-->
                                    <!--                                    <div class="sm-res">-->
                                    <!--                                        <img class="gugl mr-2 fl-j-c" src="../../assets/web/login/g.png">-->
                                    <!--                                        <img class="fb flj-c " src="../../assets/web/login/fb.png">-->

                                    <!--                                    </div>-->
                                    <div class="try">

                                        <img src="../../assets/web/login/bgi-new.png">
                                    </div>
                                    <div class="strip-color">

                                    </div>
                                </div>
                            </s-form>
                        </div>
                    </validation-observer>
                </div>

            </div>

            <div class="col-12 col-lg-6 section-4 d-sm-none">
                <div class="custom-border"></div>
                <div class="fl-x fl-a-c h-100">
                    <div class="pos-r p-3">
                        <div class="p-all">
                            <div class="section-5 mt-4">
                                <img class="text-center" src="../../assets/web/login/logoo.png">
                            </div>
                            <div class="section-6 mt-6 mb-3">
                                <h2 class="font-jost-medium fs-6 mb-4">Welcome To KLDB Training Courses</h2>
                                <p class="font-lato-medium fs--1  lh" align="justify">What our variations of
                                    passages with lives psumedavailable alteration in some variations of passages orem
                                    Ipsume passages with lives psum available alterat in some variations of passages
                                    orem Ipsume lives psum available alteration in some variations passages orem Ipsume
                                    variety passages orem Ipsume lives psum available alteration n some variations
                                    passages orem Ips variety </p>

                            </div>
                            <div class="mb-6 mt-4 custom-center">
                                <hr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</template>

<script>
import urls from '@/data/urls';
import { mapActions } from 'vuex';
import axios from 'secure-axios';

export default {
    name: 'Login',
    data () {
        return {
            section: 'login',
            forgotPasswordLoading: false,
            forgotPasswordMessage: '',
            forgotMail: '',
            loginUrl: urls.auth.login,
            id: this.$route.params.id,
            loading: false,
            model: {},
            errorMessage: '',
            rules: {
                username: {
                    required: true
                },
                password: {
                    required: true
                }
            }
        };
    },
    methods: {
        ...mapActions(['setUser']),
        clearForm () {
            this.errorMessage = '';
            this.forgotPasswordMessage = '';
            this.model = {};
            const formValidator = this.$refs.formValidator;
            formValidator.reset();
        },
        setSection (section) {
            this.section = section;
            this.errorMessage = '';
            this.forgotPasswordMessage = '';
        },
        async forgotPassword () {
            const that = this;
            this.errorMessage = '';
            this.forgotPasswordMessage = '';
            that.forgotPasswordLoading = true;
            const response = await axios.form(urls.auth.forgotPassword, {
                email: that.forgotMail
            });
            const json = response.data;
            if (json.error === false) {
                this.forgotPasswordMessage = 'We have send an E-Mail to your registered E-Mail ID. Please go through the mail to continue with your password reset.';
                setTimeout(() => {
                    this.$router.go();
                }, 5000);
            } else {
                this.forgotPasswordMessage = json.errors.Email;
            }
            that.forgotPasswordLoading = false;
        },
        async submitClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.auth.login, that.model);
            const json = response.data;
            if (!json) {
                this.loginError(json.errors);
            } else if (json.error === false) {
                this.loginClicked(json);
            } else {
                this.loginError(json.errors);
            }
            that.loading = false;
        },
        loginClicked (response) {
            if (response.user) {
                const redirectUrl = sessionStorage.getItem('redirectPath');
                this.setUser({
                    ...response.user
                });
                if (this.id) {
                    this.$router.push(redirectUrl || { path: '/course-offered/' + this.id + '/details/' });
                } else {
                    this.$router.push(redirectUrl || { path: '/' });
                }
            }
            localStorage.clear();
        },
        loginError (errors) {
            const formValidator = this.$refs.formValidator;
            formValidator.setErrors(errors);
            this.errorMessage = 'Invalid Credentials';
        }
    }
};
</script>

<style lang="scss" scoped>

.form-input-w-22re .form-control {
    width: 22rem !important;
    margin-top: 8px !important;
}

.sec {
    background-color: #f3f3f3;
}

.lg-no-dis {
    display: none;
}

.pbtn-1 {
    padding-left: 3.5rem !important;
    padding-bottom: 0.7rem !important;
    padding-top: 0.7rem !important;
    padding-right: 3.5rem !important;
}

.lh {
    line-height: 1.5rem !important;
}

.sec-1 {
    text-align: center;

}

.strip-color {
    background-color: #115a3d;
    width: 100%;
    height: 0.8rem;
    position: absolute;
    left: 0px;
    /*top: 565px;*/
    bottom: 0px;
}

.j-center {
    justify-content: center;
}

.sec-left {
    margin-left: 136px;
}

.sec-left1 {
    margin-left: 340px;
    position: relative;
    top: -11px;
}

.section-3 {
    text-align: center;
}

.section-4 {
    background-image: url("../../assets/web/login/login.jpg");
    background-position: center 100%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;

}

.social-bg {
    background-image: url('../../assets/web/login/bgi-new.png');
    background-size: cover;
    background-position: 0% 70%;
    @media (min-width: 768px) and (max-width: 1024px) {
        background-position: 26% 70% !important;
    }
}

.section-5 img {
    width: 226px;
    height: 120px;
    @media (min-width: 1400px) {
        width: 255px;
        height: 139px;
    }
}

.section-5 {
    display: flex;
    color: white;
    justify-content: center;
}

.section-6 {
    text-align: center;
    color: white;

}

.try img {
    height: 100px;
    width: 600px;
}

.custom-border {
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    border-radius: 7px;
    position: absolute;
    top: 2%;
    right: 2%;
    left: 2%;
    bottom: 2%;

}

.p-all {
    padding: 3rem;
}

hr {
    border-top: 1.5px solid white;
    width: 13%;
}

.custom-center {

    display: flex;
    justify-content: center;
}

h6 {

    margin: 20px auto;
    margin-bottom: 0;
    color: #36454F;
    text-align: center;
    font-size: 13px;
    /*max-width: 360px;*/
    max-width: 275px;
    position: relative;
}

h6:before {
    content: "";
    display: block;
    /*width: 130px;*/
    width: 97px;
    height: 0.5px;
    background: darkgrey;
    left: 0;
    top: 50%;
    position: absolute;
}

.container {
    min-height: 100vh;

}

h6:after {
    content: "";
    display: block;
    /*width: 130px;*/
    width: 97px;
    height: 0.5px;
    background: darkgrey;
    right: 0;
    top: 50%;
    bottom: 0;
    position: absolute;
}

.ml-20rem {
    margin-left: 20rem;
}

.fb {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid green;
    padding: 4px;

}

.gugl {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: white;
    border: 1px solid green;
    padding: 4px;

}

.strip img {
    width: 100%;
    height: 8px;
    position: absolute;
    left: 0px;
    /*top: 565px;*/
    bottom: 0px;

}

.nobs {
    display: none;
}

.text-black {
    color: #202020 !important;
}

.h-88 {
    height: 88%;
}

@media (max-width: 750px) {
    .try img {
        height: 200px;
        width: 400px;
        width: 400px;
    }
    .pbtn-1 {
        padding-left: 2.5rem !important;
        padding-bottom: 0.7rem !important;
        padding-top: 0.7rem !important;
        padding-right: 2.5rem !important;
    }

    .nobs {
        display: contents;
    }

    .nobs-no {
        display: none;
    }

    .custom-border {
        border: 0.5px solid #ffffff;
        border-radius: 7px;
        position: relative;
        top: 2%;
        right: 2%;
        left: 2%;
        bottom: 2%;

    }

    .sec-left1 {
        margin-left: 195px;
        position: relative;
        top: -11px;
    }

    .sm-res {
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .d-sm-none {
        display: none;
    }

    .lg-no-dis {
        display: contents;
    }

    .set-im img {
        //width: 206px;
        //height: 108px;
        height: 6.5rem;
        margin-top: 1rem;
        //margin-left: -10rem;

    }

    .sec-left {
        margin-left: 49px;
    }

    .sec-left1 {
        margin-left: 195px;
    }

    //.login-page {
    //    background-color: whitesmoke;
    //}

    .strip img {
        /*width: 642px;*/
        width: 100%;
        height: 4px;
        position: absolute;
        left: 0px;
        top: 848px;
        bottom: 0px;
    }
}

.home-icon {
    position: relative;
    width: 1.75rem !important;
    height: 1.75rem !important;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../../assets/web/login/home-icon.png");
}

</style>
